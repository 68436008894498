<template>
  <div class="page">
    <Navbar title="新增成员" type="SMP" />
    <van-form @submit="submit" class="form">
      <van-cell-group>
        <van-field
          v-model="person.name"
          name="姓名"
          label="姓名"
          :required="true"
          placeholder="请输入姓名"
          :rules="[{ required: true, message: '请输入姓名' }]"
        />
        <van-field
          v-model="person.identity"
          name="身份证号"
          label="身份证号"
          @change="identityChange"
          placeholder="请输入身份证号"
        />
        <van-field name="uploader" label="身份证照片">
          <template #input>
            <van-row class="identityImg">
              <van-col span="12"
                ><van-uploader
                  v-model="idtFontImages"
                  :after-read="idtFontRead"
                  :before-delete="imageDelete"
                  :max-count="1"
                /><br /><span class="tips fc-orange">正面照片</span></van-col
              >
              <van-col span="12"
                ><van-uploader
                  v-model="idtBackImages"
                  :after-read="idtBackRead"
                  :before-delete="imageDelete"
                  :max-count="1"
                /><br /><span class="tips fc-orange">背面照片</span></van-col
              >
            </van-row>
          </template>
        </van-field>
        <van-field
          v-model="person.birthday"
          name="出生日期"
          label="出生日期"
          placeholder="请输入求职者生日"
          readonly
          clickable
          @click="birthdayShow = true"
          :required="true"
          :rules="[{ required: true, message: '请选择出生日期' }]"
        >
        </van-field>
        <van-popup :show="birthdayShow" position="bottom">
          <van-datetime-picker
            v-model="currentDate"
            @cancel="birthdayShow = false"
            @confirm="confirmBirthday"
            type="date"
            title="选择年月日"
            :min-date="minDate"
            :max-date="maxDate"
          />
        </van-popup>
        <van-field name="uploader" label="个人照片">
          <template #input>
            <van-uploader
              v-model="photoImages"
              :after-read="photoRead"
              :before-delete="imageDelete"
              :max-count="1"
            />
            <span class="tips fc-orange">请上传个人2寸证件照片</span>
          </template>
        </van-field>
        <van-field
          v-model="person.wechat"
          label="微信号码"
          placeholder="请输入微信号码"
          :required="true"
          :rules="[{ required: true, message: '请输入微信号码' }]"
        >
        </van-field>
        <van-field
          v-model="person.phone"
          label="手机号码"
          type="tel"
          :required="true"
          placeholder="请输入手机号码"
          :rules="[{ required: true, message: '请填写手机号码' }]"
        >
          <template #button>
            <van-button
              size="mini"
              v-if="sendBtnTime > 0"
              :disabled="true"
              :color="COLOR"
              @click="sendValidate()"
              >发送验证码({{ sendBtnTime }})</van-button
            >
            <van-button
              size="mini"
              v-if="sendBtnTime <= 0"
              :color="COLOR"
              @click="sendValidate()"
              >发送验证码</van-button
            >
          </template>
        </van-field>
        <van-field
          v-model="person.vcode"
          center
          clearable
          label="短信验证码"
          :required="true"
          placeholder="请输入短信验证码"
          :rules="[{ required: true, message: '请填写短信验证码' }]"
        ></van-field>
      </van-cell-group>
      <van-row>
        <van-col offset="4" span="16" class="subbtn">
          <van-button
            icon="passed"
            size="small"
            color="#00CC99"
            class="btn"
            native-type="submit"
          >
            提交保存
          </van-button>
        </van-col>
      </van-row>
      <van-row> </van-row>
    </van-form>
    <Share ref="share" />
    <loading :show="loadingShow" />
  </div>
</template>
<script>
import { GolIdentityCheck } from '../../../../plugins/common/verify.js'
import { GolDateFormat } from '../../../../plugins/common/date.js'
import { Form, Field, CellGroup, Picker, Popup, Area, Steps, Step, Uploader, DatetimePicker } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
export default {
  components: {
    Navbar: Navbar,
    Share: Share,
    Loading: Loading,
    [Form.name]: Form,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Area.name]: Area,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Uploader.name]: Uploader,
    [DatetimePicker.name]: DatetimePicker
  },
  data () {
    return {
      operatorCode: '',
      personCode: '',
      inviterCode: '',
      profile: '',
      module: '',
      photoImages: [],
      idtFontImages: [],
      idtBackImages: [],
      step: 0,
      sendBtnTime: 0,
      genders: [],
      minDate: new Date(1950, 1, 1),
      maxDate: new Date(),
      currentDate: new Date(1988, 1, 1),
      person: {
        name: '',
        identity: '',
        birthday: '',
        idtfCode: '',
        idtbCode: '',
        photoCode: '',
        wechat: '',
        phone: '',
        vcode: ''
      },
      loadingShow: false,
      birthdayShow: false,
      validateState: true
    }
  },
  mounted () {
    var query = this.$route.query
    var code = query.code
    this.module = query.module
    this.profile = query.profile
    var inviterCode = query.inviterCode
    if (inviterCode !== '' && code !== undefined) {
      this.inviterCode = inviterCode
    }
    this.operatorCode = window.sessionStorage.getItem(this.SESSION_OPERATOR)
    this.$refs.share.default('main')
  },
  methods: {
    async imageDelete (image) {
      var pd = { code: image.code }
      const { data: res } = await this.$http.post(this.BMS_URL + '/storage/image/deleteImage', this.$qs.stringify(pd))
      if (res.status === '200') {
        if (image.key === 'PEP_PSN_CPG') {
          this.person.photoCode = ''
        } else if (image.key === 'PEP_PSN_IDTF') {
          this.person.idtfCode = ''
        } else if (image.key === 'PEP_PSN_IDTB') {
          this.person.idtbCode = ''
        }
        return true
      } else {
        return false
      }
    },
    identityChange () {
      if (GolIdentityCheck(this.person.identity)) {
        var years = this.person.identity.substr(6, 4)
        var month = this.person.identity.substr(10, 2)
        var day = this.person.identity.substr(12, 2)
        this.person.birthday = years + '-' + month + '-' + day
        this.currentDate = new Date(parseInt(years), parseInt(month) - 1, parseInt(day))
      } else {
        this.loadingShow = false
        this.person.identity = ''
        this.$dialog.alert({
          title: '信息提示',
          message: '身份证号不符合规则'
        })
      }
    },
    confirmBirthday (val) {
      this.person.birthday = GolDateFormat(val)
      this.birthdayShow = false
    },
    photoRead (file) {
      file.key = 'PEP_PSN_CPG'
      this.uploadImage(file, '证件照片')
    },
    idtFontRead (file) {
      file.key = 'PEP_PSN_IDTF'
      this.uploadImage(file, '身份证照片-正面')
    },
    idtBackRead (file) {
      file.key = 'PEP_PSN_IDTB'
      this.uploadImage(file, '身份证照片-反面')
    },
    async uploadImage (file, alias) {
      var that = this
      this.loadingShow = true
      var pd = { dictKey: file.key, content: file.content, name: file.file.name, alias: alias, attach: 'photo' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/storage/image/uploadImage', this.$qs.stringify(pd))
      if (res.status === '200') {
        file.code = res.data.code
        if (file.key === 'PEP_PSN_CPG') {
          that.person.photoCode = res.data.code
        } else if (file.key === 'PEP_PSN_IDTF') {
          that.person.idtfCode = res.data.code
        } else if (file.key === 'PEP_PSN_IDTB') {
          that.person.idtbCode = res.data.code
        }
      }
      this.loadingShow = false
    },
    async submit () {
      this.loadingShow = true
      var pd = {
        name: this.person.name,
        identity: this.person.identity,
        birthday: this.person.birthday,
        idtfCode: this.person.idtfCode,
        idtbCode: this.person.idtbCode,
        phone: this.person.phone,
        wechat: this.person.wechat,
        photoCode: this.person.photoCode,
        inviterCode: this.inviterCode,
        validate: this.person.vcode
      }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/createPerson', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.personCode = res.data.code
        this.createMebPerson()
        this.createClient()
      } else {
        this.loadingShow = false
        this.$dialog.alert({
          title: '信息提示',
          message: res.msg
        }).then(() => {
        })
      }
    },
    async createClient () {
      var operatorCode = window.sessionStorage.getItem(this.SESSION_OPERATOR)
      var sellerCode = window.sessionStorage.getItem(this.SESSION_SELLER)
      var refereeCode = window.sessionStorage.getItem(this.SESSION_REFEREE)
      var pd = {
        name: this.person.name,
        phone: this.person.phone,
        personCode: this.personCode,
        business: 'RCT',
        type: 'ITN',
        operator: operatorCode,
        seller: sellerCode,
        referee: refereeCode
      }
      const { data: res } = await this.$http.post(this.BMS_URL + '/call/client/createClient', this.$qs.stringify(pd))
      if (res.status === '200') {
      }
    },
    async createMebPerson () {
      var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
      var referee = window.sessionStorage.getItem(this.SESSION_REFEREE)
      var comment = window.sessionStorage.getItem(window.const.global.MEMBER_PERSON_COMMENT)
      if (comment === undefined || comment === null) {
        comment = ''
      }
      var pd = {
        personCode: this.personCode,
        sellerCode: seller,
        refereeCode: referee,
        comment: comment
      }
      const { data: res } = await this.$http.post(this.BMS_URL + '/member/person/createPerson', this.$qs.stringify(pd))
      this.loadingShow = false
      if (res.status === '200') {
        this.taskComplete()
        this.$dialog.alert({
          title: '信息提示',
          message: '新增个人信息成功'
        }).then(() => {
          this.returnPre()
        })
      } else {
        this.$dialog.alert({
          title: '信息提示',
          message: res.msg
        }).then(() => {
        })
      }
    },
    async taskComplete () {
      var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
      var seller = window.sessionStorage.getItem(this.SESSION_SELLER)
      var user = window.sessionStorage.getItem(this.SESSION_USER)
      var attach = { personCode: this.personCode }
      var pd = { key: 'MSA_PERSON_INVITER', operator: operator, seller: seller, user: user, amount: 1, description: '', attach: JSON.stringify(attach) }
      const { data: res } = await this.$http.post(this.UMS_URL + '/assess/task/taskComplete', this.$qs.stringify(pd))
      if (res.status === '200') {
        console.log(res.data)
      }
    },
    async sendValidate () {
      var pd = { phone: this.person.phone }
      var that = this
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/sendValidate', this.$qs.stringify(pd))
      if (res.status === '200') {
        that.validateState = true
        that.sendBtnTime = 60
        that.countdown()
      }
    },
    countdown () {
      var that = this
      setTimeout(function () {
        that.sendBtnTime--
        if (that.sendBtnTime > 0) {
          that.countdown()
        }
      }, 1000)
    },
    returnPre () {
      this.$router.push({ path: '/mde/people/person/dtlEdit', query: { personCode: this.personCode, profile: this.profile } })
    }
  }
}
</script>
<style lang="less" scoped>
.identityImg {
  text-align: center;
}
.tips {
  font-size: 12px;
  line-height: 18px;
}
.step {
  text-align: left;
}
.form {
  padding: 20px 5px;
}
.subbtn {
  margin: 20px auto;
}
.btn {
  width: 120px;
}
</style>
